import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { first } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionInvoiceService {

    constructor(private http: HttpClient) {}

    updateSubscriptionInvoice(invoiceId: number, data: any) {
        return this.http
          .put<any>(
            `${environment.url_api}/dashboard/invoices/${invoiceId}`,
            data,
          )
          .pipe(
            first(),
            // map((res: any) => res)
          );
    }
      
    paidInvoice(invoiceId: number) {
        return this.http
          .put<any>(
            `${environment.url_api}/dashboard/invoices/${invoiceId}/paid`,
            {},
          )
          .pipe(
            first(),
            // map((res: any) => res)
          );
    }
}